// preloader

$(document).ready(function () {
    const preloader = $('.preloader svg circle');
    if (preloader.length>0) {
        let i = 0;
        var intervalID;
        intervalID = setInterval(function () {
            if (i <= preloader.length) {
                preloader.eq(i).css('opacity','1');
                i++;
            } else {
                clearInterval(intervalID);
                // setTimeout(function () {
                //     $('.preloader').fadeOut(1000);
                // },3500);
            }
        }, 3);
    }
});

// fancybox

Fancybox.bind("[data-fancybox]", {
    // Your custom options
});

const
    page1msg1 = $('.js-page-1-msg-1'),
    page1msg2 = $('.js-page-1-msg-2'),
    page1msg3 = $('.js-page-1-msg-3'),
    page1msg4 = $('.js-page-1-msg-4'),
    page1msg5 = $('.js-page-1-msg-5'),
    page1msg6 = $('.js-page-1-msg-6'),
    page1msg7 = $('.js-page-1-msg-7'),
    page2msg1 = $('.js-page-2-msg-1'),
    page2msg2 = $('.js-page-2-msg-2'),
    page2msg3 = $('.js-page-2-msg-3'),
    page2msg4 = $('.js-page-2-msg-4'),
    page2msg5 = $('.js-page-2-msg-5'),
    page2msg6 = $('.js-page-2-msg-6'),
    page2msg7 = $('.js-page-2-msg-7'),
    page2msg8 = $('.js-page-2-msg-8'),
    page1mobBTN = $('.js-triangle-2'),
    page2mobBTN = $('.js-triangle-4'),
	page2mobBTN5 = $('.js-triangle-5');

// PAGE 1

page1msg1.click(function () {
    $(this).closest('.msg-item').removeClass('step-1').addClass('active step-2');
    $('#blablatext').focus();
});

$( document ).on( 'click', '.js-cancel', function() {
	const $steps = $( this ).closest( '.steps' ),
		$box = $( this ).closest( '.msg' );
	$box.children( '.msg-item' ).css( 'display', '' );
	$steps.removeClass( 'active step-2 step-3 step-4 step-5 step-6 step-7 step-8 step-9' ).addClass( 'step-1' );
	$( '.main .triangle-4' ).removeClass( 'unactive' );
});

page1msg2.click(function () {
    $(this).closest('.msg-item').removeClass('step-2').addClass('step-3');
    $('.triangle-2').addClass('hidden');
    setTimeout(function () {
        $('.msg-item .btn-wrapper').addClass('active');
    },100);
});

page1msg3.click(function () {
    $(this).closest('.msg-item').removeClass('step-3').addClass('step-4');
    $('.triangle-2').removeClass('hidden');
});

page1msg4.click(function () {
    $('.msg-item .btn-wrapper').removeClass('active');
    $(this).closest('.msg-item').removeClass('step-3').addClass('step-5');
    setTimeout(function () {
        $('.msg-item .btn-wrapper').addClass('active');
    },100);
});

page1msg5.click(function () {
    $(this).closest('.msg-item').removeClass('step-5').addClass('step-6');
    $('.triangle-2').removeClass('hidden');
});

page1msg6.click(function () {
    $(this).closest('.msg-item').removeClass('step-5').addClass('step-7');
    $('.triangle-2').removeClass('hidden');
});

page1msg7.click(function () {
    $(this).closest('.msg-item').removeClass('step-7 msg-item--blue msg-item').addClass('step-8');
});

page1mobBTN.click(function (){
    $('.msg-1').addClass('active');
});

page2mobBTN5.click(function (){
	$('.msg-5').addClass('active');
});

// PAGE 2

page2msg1.click(function () {
    $(this).closest('.msg-item').removeClass('step-1').addClass('active step-2');
	$('.triangle-5').addClass('hidden');
    setTimeout(function () {
        $('.msg-item .btn-wrapper').addClass('active');
    },100);
});

page2msg2.click(function () {
	$('.msg-item .btn-wrapper').removeClass('active');
    $(this).closest('.msg-item').removeClass('step-2').addClass('step-3');
	$('.triangle-5').removeClass('hidden');
});

page2msg3.click(function () {
    $('.msg-item .btn-wrapper').removeClass('active');
    $(this).closest('.msg-item').removeClass('step-2').addClass('step-4');
    setTimeout(function () {
        $('.msg-item .btn-wrapper').addClass('active');
    },100);
});

page2msg4.click(function () {
	$('.msg-item .btn-wrapper').removeClass('active');
    $(this).closest('.msg-item').removeClass('step-4').addClass('step-5');
	$('.triangle-5').removeClass('hidden');
});

page2msg5.click(function () {
	$('.msg-item .btn-wrapper').removeClass('active');
    $(this).closest('.msg-item').removeClass('step-4').addClass('step-6');
	$('.triangle-5').removeClass('hidden');
});

page2msg6.click(function () {
    $(this).closest('.msg-item').removeClass('step-6 msg-item--pink msg-item').addClass('step-7');
});

page2msg7.click(function () {
    $(this).closest('.msg-item').removeClass('step-1').addClass('active step-8');
    $('.msg-5 > .msg-item:not(.active)').hide();
});

page2msg8.click(function () {
    $(this).closest('.msg-item').removeClass('step-8 msg-item--green msg-item').addClass('step-9');
});

page2mobBTN.click(function (){
    $('.mobile').toggleClass('active');
});

// SWIPE

const msg1 = $(".msg-1");
const msg2 = $(".mobile");
const msg5 = $(".msg-5");

$(function() {
    var startX, startY, endX, endY;
    var $element = $("body");

	msg1.on("touchstart", function(e) {
        var touch = e.originalEvent.touches[0];
        startX = touch.pageX;
        startY = touch.pageY;
    });

	msg1.on("touchmove", function(e) {
        var touch = e.originalEvent.touches[0];
        endX = touch.pageX;
        endY = touch.pageY;

        var distance = startX - endX;

        if (distance > 0) {
            if (msg1.length>0) {
                msg1.addClass('active');
            }
            if (msg2.length>0) {
                msg2.addClass('active');
            }
        }
        if (distance < 0) {
            if (msg1.length>0) {
                msg1.removeClass('active');
            }
            if (msg2.length>0) {
                msg2.removeClass('active');
            }
        }
    });


	msg5.on("touchstart", function(e) {
		var touch = e.originalEvent.touches[0];
		startX = touch.pageX;
		startY = touch.pageY;
	});

	msg5.on("touchmove", function(e) {
		var touch = e.originalEvent.touches[0];
		endX = touch.pageX;
		endY = touch.pageY;

		var distance = startX - endX;

		if (distance > 0) {
			if (msg5.length>0) {
				msg5.addClass('active');
			}
		}
		if (distance < 0) {
			if (msg5.length>0) {
				msg5.removeClass('active');
			}
		}
	});
});

function msgMobile() {
	const top = $( window ).scrollTop();
	if ( window.matchMedia( '(orientation: portrait)' ).matches ) {
		if ( msg1.length && top > msg1.offset().top - $( window ).innerHeight() ) {
			setTimeout( function() {
				msg1.addClass('active');
			}, 1000 );
		}
		if ( msg5.length && top > msg5.offset().top - $( window ).innerHeight() ) {
			setTimeout( function() {
				msg5.addClass('active');
			}, 1000 );
		}
	}
}
msgMobile();
$( window ).on( 'scroll', function() {
	msgMobile();
});

// calendar

$(document).ready(function(){

    // one date
    $('[lang="en"] #datePick').datepicker({
        dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        showOtherMonths: true,
        selectOtherMonths: true,
        dateFormat: 'dd-mm-yy',
        onSelect: function(dateText) {
            var dates = this.value.replace(/\s/g, "");
            $('#dates').val(dates);
        }
    });
	if ( $( '[lang="de"]' ).length ) {
		$.datepicker.setDefaults($.datepicker.regional["de"]);
	}
	$('[lang="de"] #datePick').datepicker({
		monthNames: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
		dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag','Samstag'],
		dayNamesMin: ['S', 'M', 'D', 'W', 'D', 'F', 'S'],
		showOtherMonths: true,
		selectOtherMonths: true,
		dateFormat: 'dd-mm-yy',
		onSelect: function(dateText) {
			var dates = this.value.replace(/\s/g, "");
			$('#dates').val(dates);
		}
	});
    $('#dates').val($('#datePick').val());

    // many dates
    // $('#datePick').multiDatesPicker({
    //     dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    //     showOtherMonths: true,
    //     selectOtherMonths: true,
    //     dateFormat: 'dd-mm-yy',
    //     onSelect: function(dateText) {
    //         var dates = this.value.replace(/\s/g, "");
    //         $('#dates').val(dates);
    //     }
    // });

	if ( $( '.msg-4 .msg-item a' ).length ) {
		const $parent = $( '.msg-4 .msg-item' ),
			maxH = $parent.innerHeight();
		$parent.append( '<div class="msg-item__preview"></div>' );
		const $preview = $parent.find( '.msg-item__preview' );
		$preview.css( 'width', $preview.innerHeight() );
		$( '.msg-4 .msg-item a' ).each( function() {
			const $this = $( this ),
				index = $this.index();
			let src = '';
			if ( $this.attr( 'href' ) !== '' ) {
				src = $this.attr( 'href' );
			}
			$parent.find( '.msg-item__preview' ).append( '<img src="' + src + '" />' );
			$this.on( 'mouseenter', function() {
				$preview.find( 'img' ).removeClass( 'show' );
				$preview.find( 'img' ).eq( index ).addClass( 'show' );
			}).on( 'mouseleave', function() {
				$preview.find( 'img' ).eq( index ).removeClass( 'show' );
			});
		});
		$( window ).on( 'resize', function() {
			$preview.css( 'width', $preview.innerHeight() );
		});
	}
});

// form

$("#form").validate({
    rules: {
        company: {
            required: true
        },
        branch: {
            required: true
        },
        exp: {
            required: true
        }
    },
    submitHandler: function(form) {
        $.ajax({
            type:"POST",
            url:"ajax.php",
            data:"action=send_form&"+$("#form").serialize(),
            success:function success(data){
            $(".calendar").hide();
            $("button[type=\"submit\"]").hide();
            $(".msg-thx").fadeIn();
            $(".main").addClass("form-send");
        }});
    }
});

function inputActive() {
    $('.activeinput').keyup(function () {
        if ($(this).val().length > 0) {
            $(this).parents('.msg-withform').addClass('active');
        } else {
            $(this).parents('.msg-withform').removeClass('active');
        }
    });
}

inputActive();

$('.js-nopreload').click(function () {
    setCookie('no-preload','1',7);
});

const goBack = function (){
    setCookie('no-preload','1',7);
    history.back();
};
